import axios from 'axios';
import AuthContext from 'context/AuthProvider';
import useAuth from 'hooks/useAuth';
import { useContext } from 'react';
import authService from "./services/auth.service";
import TokenService from './services/token.service';

const BASE_API_URL = '';

const axiosService = axios.create({
    baseURL: BASE_API_URL,
    headers: { 'Content-Type': 'application/json' }
});

axiosService.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosService.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if(error.response.status == "401" && error.response.statusText == "Unauthorized"){
        
        const user = JSON.parse(localStorage.getItem("user"));
        
        if(user.hasOwnProperty('refreshToken')){
            if(error.response.data.statusCode == 203 && error.response.data.statusCode != 235){
                const data = await authService.refreshToken(user.refreshToken)

                originalRequest.headers['Authorization'] = 'Bearer ' + data.token;

                localStorage.setItem("user", JSON.stringify(data));

                return axiosService(originalRequest);
            }else if(error.response.data.statusCode == 235){
                TokenService.removeUser()
            }
        }else{
            TokenService.removeUser()
        }
    }
    return Promise.reject(error);
});


export default axiosService