
import AuthContext from "context/AuthProvider";
import { useContext } from "react";
import { Route,useLocation, Redirect } from "react-router-dom";
import useAuth from "./useAuth";

const RequireAuth = ({ allowedRoles, children, ...rest }) => {
    const { getAuth,closeSession } = useContext(AuthContext);

    const auth = getAuth();
    
    return (
      <Route
        {...rest}
        render={({ location }) =>{

          let statement = children
          
          //console.log(location)
          if(location.pathname == "/"){
            statement = <Redirect
              to={{
                pathname: "/admin",
                state: { from: location }
              }}
            />
          }
          let roles = auth?.perfil ? JSON.parse(auth?.perfil?.roles) : []

          let autorizado = auth?.token ? (roles?.find(role => allowedRoles?.includes(role)) ? true : false) : (false)
          
          if (!autorizado){
            //closeSession()
          }

          return (
            autorizado ? (statement)
            : <Redirect to={{ pathname: "/auth/signin",state: { from: location }}}/>
          )

        }}
      />
    );
}

export default RequireAuth;



