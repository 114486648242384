import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { DataGrid,esES,GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

function MuiTable(props) {

    const theme = createTheme();
    const { columns,rows,progressPending } = props;

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
          </GridToolbarContainer>
        );
      }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ width: '100%' }}>
                <DataGrid 
                    components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                    }}
                    loading={progressPending}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                    style={{height:500}} 
                    rows={rows} 
                    columns={columns} />
            </div>
        </ThemeProvider>
    );
}

export default MuiTable;
