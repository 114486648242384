// Chakra Icons
import { ChevronDownIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,

  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";


import { SettingsIcon } from "components/Icons/Icons";

import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import AuthContext from "context/AuthProvider";
import PropTypes from "prop-types";
import React, { useContext } from "react";


export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const { closeSession,getAuth } = useContext(AuthContext);
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  const { colorMode, toggleColorMode } = useColorMode();
  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }

  const auth = getAuth();

  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
  
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {auth.nombre+" "+auth.apPaterno}
        </MenuButton>
        <MenuList>
          
          <MenuItem onClick={closeSession} minH='48px' >
            <span>Cerrar sesion</span>
          </MenuItem>
          
        </MenuList>
      </Menu>
    </Flex>
  );
}

/*
  <MenuItem onClick={toggleColorMode} minH='48px' >
    <span> Modo {colorMode == "light" ? "oscuro" : "claro"}  </span>
  </MenuItem>
*/

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
