// Chakra imports
import {
  Text,
  useColorModeValue,
  Flex,
  Button
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import {useParams} from 'react-router-dom'
import IncidenciasService from "api/services/incidencias.service";
import ProgressComponent from "components/ProgressComponent/ProgressComponent";
import IncidenciaItem from "./IncidenciaItem";


const IncidenciasDetails = () => {
  
  let { id } = useParams();

  //console.log(id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [loadingZip, setLoadingZip] = useState(false)
  const [loadingPDF, setLoadingPDF] = useState(false)
  

  const getIncidencia = (id) => {//incidencias/:id

    IncidenciasService.getIncidenciaById(id).then((incidencia)=>{
      //console.log(incidencia)
      if(incidencia != null){
        setData(incidencia != null ? incidencia : [])
        setLoading(false)  
      }
    })
  }
  
  
  useEffect(() => {
    getIncidencia(id)
  }, []);
  

  const descargarPdf = (id,data)=>{
    setLoadingPDF(true)
    IncidenciasService.getPdfIncidencia(id,data).then((res)=>{
      setLoadingPDF(false)
    });
  }

  const descargarZip = (id,data)=>{
    setLoadingZip(true)
    IncidenciasService.getZipIncidencia(id,data).then((res)=>{
      setLoadingZip(false)
    });
  }


  

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex direction='column' pt={{ base: "75px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader >
          <Text fontSize='xl' style={{flex:1}} color={textColor} fontWeight='bold'>
            Detalle incidencia
          </Text>

          

          <Button 
           isLoading={loadingPDF}
           variant='solid'
           colorScheme='blue' ml="5" onClick={() => descargarPdf(data.id,data)} >Descargar PDF</Button>
          <Button 
          isLoading={loadingZip}
          variant='solid'
          colorScheme='blue' ml="5" onClick={() => descargarZip(data.id,data)} >Descargar ZIP</Button>
      
        </CardHeader>
        <CardBody >
        {(
          loading ? (
              <ProgressComponent />
            ) : (
              <div>
                <IncidenciaItem itemIncidencia={data} />
              </div>
            )
        )}
          
        </CardBody>
      </Card>
    </Flex>
  );
};

export default IncidenciasDetails;
