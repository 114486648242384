import axiosService from "../axios.service";



const login = (username, password) => {
  return axiosService
    .post("/users/authenticate-web", {
      username,
      password,
    })
    .then((response) => {
      return response.data;
    });
};
const refreshToken = (refreshToken) => {
  return axiosService
    .post("/users/refresh-token", {
      refreshToken,
    })
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
  refreshToken
};
