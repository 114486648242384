import axiosService from "../axios.service";
import authHeader from "./auth-header";
import fileDownload from 'js-file-download'

const getReportesHabilitados = async () => {
    let reportes =  await axiosService.get("/web/reportes", { headers: authHeader() }).then((data)=>{
        return data.data;
    })

    return reportes;
};


const getExcelReportes = async (body) => {
  await axiosService.post("/web/reportes/generador",body, { headers: authHeader(),responseType: 'blob'}).then((response)=>{
    fileDownload(response.data, "Reportes.xlsx")
  })
};


export default {
    getReportesHabilitados,
    getExcelReportes
};