import {
  Text,
  useColorModeValue,
  Flex,
  
} from "@chakra-ui/react";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import MuiTable from "components/Tables/MuiTable";
import React, { useContext, useEffect, useState } from "react";
import moment from 'moment'
import IncidenciasService from "api/services/incidencias.service";

import { BsEye,BsFileEarmarkPdf,BsFileEarmarkZip } from "react-icons/bs";

import {Link} from 'react-router-dom'
import AuthContext from "context/AuthProvider";
import { Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

const IncidenciasList = () => {
  moment(new Date()).format("DD/MM/YYYY");
  const [loadingZip, setLoadingZip] = useState([])
  const [loadingPDF, setLoadingPDF] = useState([])
  const [disableZip, setDisableZip] = useState("")
  const [disablePDF, setDisablePDF] = useState("")
  
  const descargarPdf = (id,data)=>{
    setLoadingPDF([...loadingPDF,id])
    setDisablePDF(id)
    IncidenciasService.getPdfIncidencia(id,data).then((res)=>{
      setLoadingPDF(loadingPDF.filter(data=>data != id))
      setDisablePDF("")
    });
  }

  const descargarZip = (id,data)=>{
    
    setDisableZip(id)
    setLoadingZip([...loadingZip,id])
    IncidenciasService.getZipIncidencia(id,data).then((res)=>{
      setLoadingZip(loadingZip.filter(data=>data != id))
      setDisableZip("")
    });
  }

  const columns  = [
    { field: 'id', headerName: 'id', width: 150 },
    { field: 'Incidencia', headerName: 'Incidencia', width: 250, valueGetter: (params) =>(params.row.tipo_incidencium.nombre)},
    { field: 'Operador', headerName: 'Operador', width: 250, valueGetter: (params) =>(params.row.usuario.nombre +" "+params.row.usuario.apPaterno)},
    { field: 'Sucursal', headerName: 'Sucursal', width: 250, valueGetter: (params) =>(params.row.sucursal.nombre)},
    { field: 'fechaRegistro', headerName: 'Fecha', width: 250, valueGetter: (params) =>(moment(params.row.fechaRegistro).format("DD/MM/YYYY HH:mm:ss"))},
    { field: 'Opciones', headerName: 'Opciones', width: 250, 
      renderCell: (params) => {
        
        return (
          <div>
            <Link to={`/admin/incidencias-details/${params.row.id}`}>
            <Button colorScheme='teal' variant="outlined" >
              <BsEye></BsEye> 
            </Button>
            </Link>
            <LoadingButton disabled={disablePDF != "" && disablePDF != params.row.id ? true : false } style={{marginLeft:5}} onClick={() => descargarPdf(params.row.id,params.row)} loading={loadingPDF.find(element => element == params.row.id)} colorScheme='teal' variant='outlined' >
              <BsFileEarmarkPdf></BsFileEarmarkPdf>
            </LoadingButton>
            <LoadingButton disabled={disableZip != "" && disableZip != params.row.id ? true : false } style={{marginLeft:5}} onClick={() => descargarZip(params.row.id,params.row)} loading={loadingZip.find(element => element == params.row.id)} colorScheme='teal' variant='outlined' >
              <BsFileEarmarkZip></BsFileEarmarkZip> 
            </LoadingButton>
          </div>)
      },
    },
  ];

  const [data, setData] = useState([])
  const [progressPending, setProgressPending] = useState(true)
  const { getAuth } = useContext(AuthContext);
  const auth = getAuth();
  const allowedRoles = ["ROLE_INSPECTOR_ATM"]
  const getIncidencias = () => {
    let roles = auth?.perfil ? JSON.parse(auth?.perfil?.roles) : [];

    let autorizado = auth?.token ? (roles?.find(role => allowedRoles.includes(role)) ? true : false) : (false)
    
    if(autorizado){
      IncidenciasService.getIncidenciasFiltradas().then((incidencias)=>{
        setProgressPending(false)
        setData(incidencias != null ? incidencias : [])
      })
    }else{
      IncidenciasService.getAllIncidencias().then((incidencias)=>{
        setProgressPending(false)
        setData(incidencias != null ? incidencias : [])
      })
    }
   
  }

  useEffect(() => {
    getIncidencias()
  }, []);
  
  

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex direction='column' pt={{ base: "75px", md: "75px" }}>
      <Card style={{ height: 700, width: '100%' }} overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Incidencias
          </Text>
        </CardHeader>
        <CardBody>
          <Card >
              <MuiTable rows={data} progressPending={progressPending} columns={columns} />
          </Card>
        </CardBody>
      </Card>
    </Flex>
  );
};

/*
<DataTable
                  columns={captions}
                  data={data}
                  defaultSortFieldId={5}
                  defaultSortAsc={false}
                  progressPending={progressPending}
                  progressComponent={<ProgressComponent />}
                  pagination
                  selectableRows
                  noDataComponent="Sin registros"
                />
*/

export default IncidenciasList;
