import axiosService from "../axios.service";
import authHeader from "./auth-header";

const getAllJobs = async () => {
  let jobs =  await axiosService.get("/cronjobs/jobs-list", { headers: authHeader() }).then((data)=>{
    return data.data;
  })

  //console.log("holaaaaaaaa",jobs)
  return jobs;
};

const startJobById = async (jobId) => {
  let jobs =  await axiosService.get("/cronjobs/jobs-start-id/"+jobId, { headers: authHeader() }).then((data)=>{
    return data.data.mensaje;
  })

  //console.log("holaaaaaaaa",jobs)
  return jobs;
};

const stopJobById = async (jobId) => {
  let jobs =  await axiosService.get("/cronjobs/jobs-stop-id/"+jobId, { headers: authHeader() }).then((data)=>{
    return data.data.mensaje;
  })

  //console.log("holaaaaaaaa",jobs)
  return jobs;
};


export default {
    getAllJobs,
    startJobById,
    stopJobById
};