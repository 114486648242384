import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import Unauthorized from 'views/Unauthorized';
import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import RequireAuth from "./hooks/RequireAuth";
import { AuthProvider } from "./context/AuthProvider";

ReactDOM.render(
  <AuthProvider>
    <HashRouter>
      <Switch>
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path={`/auth`} component={AuthLayout} />
        <RequireAuth allowedRoles={["ROLE_ADMIN","ROLE_WEB"]} path="*">
          <Route path={`/admin`} component={AdminLayout} />
        </RequireAuth>
      </Switch>
    </HashRouter>
  </AuthProvider>,
  document.getElementById("root")
);
