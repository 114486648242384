// Chakra imports
import {
  Flex,
  Text,
  Grid,
  Image,
  Select,
  SimpleGrid,
  useColorModeValue,
  Button
} from "@chakra-ui/react";

import moment from 'moment'
import React, { useEffect, useState } from "react";
import {RangeDatepicker,SingleDatepicker} from "chakra-dayzed-datepicker"
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ReporteService from "api/services/reporte.service";
import IncidenciaService from "api/services/incidencias.service";

export default function Reportes() {
  
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [fechaSeleccionada, setDate] = useState(new Date());
  const [incidenciaSeleccionada, setIncidenciaSeleccionada] = useState();
  const [reporteSeleccionado, setReporteSeleccionado] = useState(new Date());

  const [reportesList, setReportes] = useState([]);
  const [incidenciaList, setIncidencias] = useState([]);

  const [filtroDiario, setFiltroDiario] = useState(false);
  const [filtroRango, setFiltroRango] = useState(false);
  const [filtroIncidencia, setFiltroIncidencia] = useState(false);

  const roles = ["ROLE_FILTRO_DIARIO","ROLE_FILTRO_RANGO","ROLE_FILTRO_INCIDENCIA"];
  const [loading, setLoading] = useState(false);
  const MONTH_NAMES_DEFAULT = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic"
  ];
  const DAY_NAMES_DEFAULT = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"];
  const DATE_FORMAT_DEFAULT = "dd-MM-yyyy";

  const getReportes = () => {
    ReporteService.getReportesHabilitados().then((reportes)=>{
      setReportes(reportes);
    })
  }

  const getIncidencias = () => {
    IncidenciaService.getTipoIncidencias().then((reportes)=>{
      setIncidencias(reportes);
    })
  }


  const handleChange = (data) => {
    let valorSeleccionado = data.target.value
    if(valorSeleccionado != ""){
      const found = reportesList.find(element => element.id == valorSeleccionado);
      setReporteSeleccionado(found)
      const roles = JSON.parse(found.roles)
      if(roles.find(element => element == "ROLE_FILTRO_DIARIO")){
        setFiltroDiario(true)
      }else{
        setFiltroDiario(false)  
      }

      if(roles.find(element => element == "ROLE_FILTRO_RANGO")){
        setFiltroRango(true)
      }else{
        setFiltroRango(false)    
      }

      if(roles.find(element => element == "ROLE_FILTRO_INCIDENCIA")){
        setFiltroIncidencia(true)
      }else{
        setFiltroIncidencia(false)    
      }
      
    }else{
      setFiltroDiario(false)  
      setFiltroRango(false)    
      setFiltroIncidencia(false)    
    }
  }

  const handleChangeIncidencias = (data) => {
    let valorSeleccionado = data.target.value
    if(valorSeleccionado != ""){
      const found = incidenciaList.find(element => element.id == valorSeleccionado);
      setIncidenciaSeleccionada(found)
    }
  }
  
  const submitReportes = () => {
    setLoading(true)
    ReporteService.getExcelReportes({
      desde: moment(selectedDates[0]).format("YYYY-MM-DD")  ,
      hasta: moment(selectedDates[1]).format("YYYY-MM-DD")   ,
      dia: moment(fechaSeleccionada).format("YYYY-MM-DD"),
      incidencia: incidenciaSeleccionada?.id,
      reporte: reporteSeleccionado.id,
    }).then((data)=>{
      setLoading(false)
    })
  }

  useEffect(() => {
    getReportes()
    getIncidencias()
  }, []);

 

  return (
    <Flex direction='column' pt={{ base: "75px", md: "75px" }}>
      <Card style={{ height: 700, width: '100%' }} overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl'  fontWeight='bold'>
            Reportes
          </Text>
        </CardHeader>
        <CardBody>
          <Card >
            <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'>
              <Select onChange={(data)=>{handleChange(data)}} placeholder='Seleccione un reporte'>
                {
                  reportesList.map((row) => {
                    return (<option value={row.id}>{row.nombre}</option>)
                  })
                }
              </Select>
              { filtroIncidencia && (
                <Select onChange={(data)=>{handleChangeIncidencias(data)}} placeholder='Seleccione una incidencia'>
                {
                  incidenciaList.map((row) => {
                    return (<option value={row.id}>{row.nombre}</option>)
                  })
                }
              </Select>
              )}
              
              { filtroDiario && (
                <SingleDatepicker
                  configs={{
                    dateFormat: DATE_FORMAT_DEFAULT,
                    monthNames: MONTH_NAMES_DEFAULT,
                    dayNames: DAY_NAMES_DEFAULT
                  }}
                  name="date-input"
                  date={fechaSeleccionada}
                  onDateChange={setDate}
                />
              )}
              { filtroRango && (
                <RangeDatepicker
                  configs={{
                    dateFormat: DATE_FORMAT_DEFAULT,
                    monthNames: MONTH_NAMES_DEFAULT,
                    dayNames: DAY_NAMES_DEFAULT
                  }}
                  selectedDates={selectedDates}
                  onDateChange={setSelectedDates}
                />
              )}
              <Button onClick={()=>{submitReportes()}} isLoading={loading} colorScheme='teal' size='md' >Descargar reporte</Button> 
            </SimpleGrid>
          </Card>
        </CardBody>
      </Card>
    </Flex>
    

  );
}
