// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Flex,
  Menu,
  IconButton,
MenuButton,
MenuList,
MenuItem
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useEffect, useState } from "react";
import JobService from '../../api/services/jobs.service'

import { HamburgerIcon } from "@chakra-ui/icons";


const CronjobsList = () => {
  const title = "";
  const captions =["#","Nombre","Estado","Activado","Opciones"];


  const [data, setData] = useState([])
  
  const showAlert = (mensaje) =>{
    alert(mensaje)
    getJobs()
  }


  const getJobs = () => {
    JobService.getAllJobs().then((jobs)=>{
      //console.log("use efect",jobs)
      setData(jobs)
    })
  }
  
  
  useEffect(() => {
    getJobs()
  }, []);
  
  

  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Flex direction='column' pt={{ base: "75px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Trabajos programados
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color={textColor}>
            <Thead>
              <Tr my='.8rem' pl='0px' color='gray.400'>
                {captions.map((caption, idx) => {
                  return (
                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              

              {data.map((row) => {
              return (
                <Tr>
                  <Th>{row.id}</Th>
                  <Th>{row.name}</Th>
                  <Th>{row.status}</Th>
                  <Th>{row.isActivated}</Th>
                  <Th>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label='Options'
                      icon={<HamburgerIcon />}
                      variant='outline'
                    />
                    <MenuList>
                      <MenuItem onClick={()=>{
                        
                        JobService.startJobById(row.id).then((jobs)=>{
                          
                          showAlert(jobs)
                        })
                      }}>
                        Iniciar trabajo programado
                      </MenuItem>
                      <MenuItem onClick={()=>{
                        JobService.stopJobById(row.id).then((jobs)=>{
                          showAlert(jobs)
                        })
                      }}>
                        Detener trabajo programado
                      </MenuItem>
                     
                    </MenuList>
                  </Menu>
                  </Th>
                </Tr>
              );
            })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CronjobsList;
