import {
    Text,
    useColorModeValue,
    Flex,
    Image,
    Grid,
    GridItem,
    List,
    ListItem,
    Button
  } from "@chakra-ui/react";

  import moment from 'moment'

  
 
import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import "./gallery.css"
import IncidenciasService from "api/services/incidencias.service";

const IncidenciaItem = ({itemIncidencia}) => {
    const textColor = useColorModeValue("gray.700", "white");
    
    const itemRender = (item) => {
        const extension = item.extension.split("/")[0]
        if(extension == "image"){
            return (
                <Flex style={{display:"relative"}}>
                    <img loading="lazy" className='image-gallery-image' style={{display:"initial"}} src={item.original} />
                    <span
                        className='image-gallery-description'
                        style={{right: '0', left: 'initial'}}
                        >
                        <a href={item.original} className='image-gallery-custom-action'>Descargar</a>
                  </span>
                </Flex>
                )
        }else{
            return (
                <div className='video-wrapper'>
                    <video style={{display:"initial"}} src={item.original} width="400" height="400" controls >
                        <source width="400" height="350" src={item.original} type={item.extension}></source>
                    </video>
                    <span
                        className='image-gallery-description'
                        style={{right: '0', left: 'initial'}}
                        >
                        <a href={item.original} className='image-gallery-custom-action'>Descargar</a>
                  </span>
                </div>
                
                )
        }  
    }
    const itemThumnailRender = (item) => {
        const extension = item.extension.split("/")[0]
        if(extension == "image"){
            return (
                <img loading="lazy" className='image-gallery-thumbnail-image' src={item.original} />
                )
        }else{
            return (
                <div className='video-wrapper'>
                    <video src={item.original} width="400" height="350"  >
                    </video>
                </div>
                
                )
        }  
    }

    let documentos = itemIncidencia.documentos?.map((item)=> {
        return { // Image item:
            original: item.path,
            thumbnail: item.path,
            extension: item.extension
        }
    })

    return (
      <Flex direction='column' >
        
        <Flex m='18px' style={{flexDirection:'column'}}>
            <Text fontSize='md' color={textColor} fontWeight='bold'>
                Nombre de la incidencia
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
                {itemIncidencia.tipo_incidencium.nombre}
            </Text>
        </Flex>
        
        <Flex m='18px' style={{flexDirection:'column'}}>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
                Fecha de registro
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
                {moment(itemIncidencia.fechaRegistro).format("DD/MM/YYYY HH:mm:ss") }
            </Text>
        </Flex>
        
        <Flex m='18px' style={{flexDirection:'column'}}>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
                Ejecutivo
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
                {itemIncidencia.usuario.nombre + " " + itemIncidencia.usuario.apPaterno + " " + itemIncidencia.usuario.apMaterno}
            </Text>
        </Flex>
        
        <Flex m='18px' style={{flexDirection:'column'}}>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
                Sucursal
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
                {itemIncidencia.sucursal.nombre}
            </Text>
        </Flex>

        <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Respuestas
        </Text>
         {
             itemIncidencia.valor_campos.map((valorCampo)=> {
                 
                return (
                <Flex m='18px' style={{flexDirection:'column'}} key={valorCampo.id}>
                    <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
                        {valorCampo.campo_dinamico.label}
                    </Text>
                    <Text fontSize='md' color='gray.500' fontWeight='400'>
                        {valorCampo.valor == "" ? "Sin respuesta" : valorCampo.valor }
                    </Text>
                </Flex>)
                }
             )  
         }

        <Text fontSize='xl' color={textColor} fontWeight='bold'>
            Documentos
        </Text>

        <Flex m='18px' >
            <ImageGallery 
            items={documentos} 
            showPlayButton={false}
            renderItem={itemRender}
            renderThumbInner={itemThumnailRender}
             />

           
        </Flex>

      </Flex>
    );
  };
  
  export default IncidenciaItem;
  