// import
import SignIn from "views/Auth/SignIn";
import IncidenciasDetails from "views/Incidencias/IncidenciasDetails";


var extraRoutes = [
  {
    path: "/incidencias-details/:id",
    name: "Detalleincidencia",
    component: IncidenciasDetails,
    layout: "/admin",
    allowedRole:["ROLE_ADMIN"]
  },
  {
    path: "/signin",
    name: "Sign In",
    component: SignIn,
    layout: "/auth",
    allowedRole:[],
  }
];
export default extraRoutes;
