// Chakra imports
import {
    Flex,
    SimpleGrid,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    useColorModeValue,
  } from "@chakra-ui/react";
  import IncidenciasService from "api/services/incidencias.service";
import { CartIcon } from "components/Icons/Icons";
  // Custom components
  
  import React from "react";
import MiniStatistics from "./MiniStatistics";
  
  const IndicadoresIncidencias = ({}) => {
    const [indicadores , setIndicadores] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const iconBoxInside = useColorModeValue("white", "white");
    
    React.useEffect(() => {
        
        IncidenciasService.getIndicadoresIncidenciasBitacoras().then((res)=>{
        
        setIndicadores(res);
        //console.log(res);
        setLoading(true);

        })



    }, []);



    function sumarIndicadores(){
        let suma = 0;
        indicadores.map((item, index) => (
        suma = suma + item.count
        ));
        return suma;
    }

    function formatNumber(number) {
        return new Intl.NumberFormat("es-ES").format(number);
    }

  
    return (
        <SimpleGrid minChildWidth='120px' spacing='20px'>
          
        {loading && (
          <MiniStatistics
            title='Total de incidencias'
            amount={formatNumber(sumarIndicadores())}
            icon={<CartIcon color={iconBoxInside} />} 
          />
        )}

        {loading && indicadores.map((item, index) => (
          <MiniStatistics
            key={index}
            title={item.nombre}
            amount={formatNumber(item.count)}
            icon={<CartIcon color={iconBoxInside} />} 
            />
        ))}

      </SimpleGrid>
    );
  };
  
  export default IndicadoresIncidencias;
  