// Chakra imports

import { Avatar, AvatarGroup } from "@mui/material";
import incidenciasService from "api/services/incidencias.service";
import Card from "components/Card/Card";

import MuiTable from "components/Tables/MuiTable";
import React from "react";
  
const ListadoSucursales = ({  }) => {
    
    
    const [loadingSucursales, setLoadingSucursales] = React.useState(true);
    const [indicadoresPorSucursales, setIndicadoresPorSucursales] = React.useState([]);
    

    React.useEffect(() => {
        

        incidenciasService.getIndicadoresPorSucursales().then((res)=>{
        

        setIndicadoresPorSucursales(res);
        //console.log(res);
        setLoadingSucursales(false);

        })



    }, []);

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }

    function stringAvatar(name) {
        
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

    const columns  = [
        
        { field: 'Sucursal', headerName: 'Sucursal', flex: 1,minWidth: 150, valueGetter: (params) =>(params.row.nombre)},
        { field: 'Asesores digitales', headerName: 'Asesores digitales', flex: 1,minWidth: 150, 
        renderCell: (params) => {
            
            
            return (
            <AvatarGroup size="sm" max={5}>
            {params.row.usuarios.map((member) => {
                return (
                <Avatar
                    {...stringAvatar(member.nombreCompleto)}
                    key={member.id}
                    _hover={{ zIndex: "3", cursor: "pointer" }}
                >
                    </Avatar>
                );
            })}
            </AvatarGroup>
            )
        },
        },
        { field: 'Incidencias registradas', headerName: 'Incidencias Registradas', flex: 1,minWidth: 150, valueGetter: (params) =>(params.row.bitacorasCount)}
        
        
    ];
  

  
    return (
      <Card>
        
            <MuiTable rows={indicadoresPorSucursales} progressPending={loadingSucursales} columns={columns} />
        
      </Card>
    );
};
  
export default ListadoSucursales;
  