import { Progress } from '@chakra-ui/react';
import React from 'react';



// The Material UI way...
const ProgressComponent = () => {


    return (
        <div style={{margin:20, width: '100%'}}>
            <Progress size='sm' isIndeterminate />
        </div>
    );
};

export default ProgressComponent