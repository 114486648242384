// import

import Dashboard from "views/Dashboard/Dashboard";
import CronjobsList from "views/CronJobs/CronjobsList";
import Profile from "views/Dashboard/Profile";
import IncidenciasList from "views/Incidencias/IncidenciasList.js";

import {
  HomeIcon,
  StatsIcon,
  PersonIcon,
} from "components/Icons/Icons";

import Reportes from "views/Reportes";

function routerApp(auth){
  var dashRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <HomeIcon color="inherit" />,
      component: Dashboard,
      layout: "/admin",
      allowedRole:["ROLE_ADMIN","ROLE_DASHBOARD"],
    },
    {
      path: "/incidencias",
      name: "Incidencias",
      icon: <StatsIcon color="inherit" />,
      component: IncidenciasList,
      layout: "/admin",
      allowedRole:["ROLE_ADMIN","ROLE_INCIDENCIA"],
    },
    {
      path: "/reportes",
      name: "Reportes",
      icon: <StatsIcon color="inherit" />,
      component: Reportes,
      layout: "/admin",
      allowedRole:["ROLE_ADMIN","ROLE_REPORTES"],
    },
    {
      path: "/cronjobs",
      name: "Cronjobs",
      icon: <StatsIcon color="inherit" />,
      component: CronjobsList,
      layout: "/admin",
      allowedRole:["ROLE_ADMIN"],
    },
    {
      name: "Cuenta",
      category: "account",
      state: "false",
      allowedRole:["ROLE_ADMIN","ROLE_WEB"],
      views: [
        {
          path: "/profile",
          name: "Perfil",
          
          icon: <PersonIcon color="inherit" />,
          secondaryNavbar: true,
          component: Profile,
          layout: "/admin",
          allowedRole:["ROLE_ADMIN","ROLE_WEB"],
        }
      ],
    },
  ];

  const roles = auth?.perfil ? JSON.parse(auth?.perfil?.roles) : [];

  const newRoutesByRoles = dashRoutes.filter((route)=> {
    return roles?.find(role => route.allowedRole?.includes(role))
  })

  return newRoutesByRoles;
}
export default routerApp;
