// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import AuthContext from "context/AuthProvider";
import React, { useContext } from "react";
import Header from "./components/Header"
import ProfileInformation from "./components/ProfileInformation";

function Profile() {

  const {getAuth} = useContext(AuthContext)

  const auth = getAuth();

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={auth.nombre+" "+auth.apPaterno}
        email={auth.correo}
        
      />
      <Grid templateColumns={{ sm: "1fr" }} gap='22px'>
        <ProfileInformation
          title={"Informacion del perfil"}
          name={auth.nombre+" "+auth.apPaterno}
          mobile={auth.telefono}
          email={auth.correo}
          location={auth.sucursal.nombre}
        />
      </Grid>
    </Flex>
  );
}

export default Profile;
