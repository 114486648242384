import axiosService from "../axios.service";
import authHeader from "./auth-header";
import fileDownload from 'js-file-download'

const getIndicadoresIncidenciasBitacoras = async () => {
  
  let indicadores =  await axiosService.get("/web/indicadores-incidencias", { headers: authHeader() }).then((data)=>{
      
      return data.data;
  }) .catch(function (error) {
      
      return []
  })

  return indicadores;
}
const getIndicadoresPorSucursales = async () => {
  
  let indicadoresSucursales =  await axiosService.get("/web/incidencias-por-sucursal", { headers: authHeader() }).then((data)=>{
      
      return data.data;
  }) .catch(function (error) {
      
      return []
  })

  return indicadoresSucursales;
}


const getAllIncidencias = async () => {
    let incidencias =  await axiosService.get("/web/incidencias/incidencias-list", { headers: authHeader() }).then((data)=>{
        return data.data;
    }) .catch(function (error) {
      return []
    })

    return incidencias;
};

const getIncidenciasFiltradas = async () => {
    let incidencias =  await axiosService.get("/web/incidencias/incidencias-filtradas", { headers: authHeader() }).then((data)=>{
        return data.data;
    }) .catch(function (error) {
      return []
    })

    return incidencias;
};

const getTipoIncidencias = async () => {
  let incidencias =  await axiosService.get("/web/incidencias/tipo-incidencias", { headers: authHeader() }).then((data)=>{
      return data.data;
  }) .catch(function (error) {
    return []
  })

  return incidencias;
};

const getIncidenciaById = async (incidenciaId) => {
    let incidencia =  await axiosService.get("/web/incidencias/"+incidenciaId, { headers: authHeader() }).then((data)=>{
        return data.data;
    }) .catch(function (error) {
      return null
    })
    return incidencia;
};

const getPdfIncidencia = async (id,data) => {
  await axiosService.get("/web/incidencias/pdf/"+id, { headers: authHeader(),responseType: 'blob' }).then((response)=>{
    
    /*const headerval = response.headers['content-disposition'];
		var filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');*/

    fileDownload(response.data, data.id+"-"+data.tipo_incidencium.nombre+"-"+data.sucursal.nombre+".pdf")
    
  }) .catch(function (error) {})
};

const getZipIncidencia= async (id,data) => {
  await axiosService.get("/web/incidencias/zip/"+id, { headers: authHeader(),responseType: 'blob' }).then((response)=>{
    /*const headerval = response.headers['content-disposition'];
		var filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');*/

    fileDownload(response.data, data.id+"-"+data.tipo_incidencium.nombre+"-"+data.sucursal.nombre+".zip")
  }) .catch(function (error) {
    return null
  })
};


export default {
    getAllIncidencias,
    getIncidenciaById,
    getIncidenciasFiltradas,
    getPdfIncidencia,
    getZipIncidencia,
    getTipoIncidencias,
    getIndicadoresIncidenciasBitacoras,
    getIndicadoresPorSucursales
};