import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuthData] = useState({});

    useEffect(() => {
        setAuth(JSON.parse(localStorage.getItem("user")));
    }, []);

    


    const setAuth = (userData) => {
        if (userData?.token) {
            setAuthData(userData)
            localStorage.setItem("user", JSON.stringify(userData));
        }
        
    }

    const closeSession = () => {

        setAuthData(null)
        localStorage.setItem("user", null);
        window.location.href = '/';
    }

    const getAuth = () => {
        return JSON.parse(localStorage.getItem("user"));
    }
    

    return (
        <AuthContext.Provider value={{ auth, setAuth,getAuth,closeSession }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;