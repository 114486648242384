// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import IncidenciasService from "api/services/incidencias.service";

import React from "react";

import MuiTable from "components/Tables/MuiTable";
import Card from "components/Card/Card";
import IndicadoresIncidencias from "./components/IndicadoresIncidencias";
import ListadoSucursales from "./components/ListadoSucursales";


// muestra indicadores desde la api
export default function Dashboard() {
  
  

  return (
    <Flex flexDirection='column' pt={{ base: "75px", md: "75px" }}>
      

      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.3fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", lg: "1fr" }}
        gap='3'
        mb={{ lg: "26px" }}>
          <GridItem colSpan={2}>
            <IndicadoresIncidencias/>
          </GridItem>

          <GridItem colSpan={1}>
                    
          </GridItem>

          <GridItem colSpan={1}>
            <ListadoSucursales/>            
          </GridItem>
      </Grid>
    
      
     
    </Flex>
  );
}
