import React,{useState,useEffect, useRef, useContext} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/logo.png";
import axiosService from "api/axios.service";
import useAuth from "hooks/useAuth";
import { useHistory } from "react-router-dom";
import authService from "../../api/services/auth.service";
import AuthContext from "context/AuthProvider";

function SignIn() {
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  
  let history = useHistory();
  const { setAuth } = useAuth();
  const [username, setUser] = useState('');
  const [password, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const { getAuth } = useContext(AuthContext);
  
  const auth = getAuth();
  
  if(auth?.token){
    history.push("/")
  }

  const allowedRoles = ["ROLE_ADMIN","ROLE_WEB"]

  useEffect(() => {
      setErrMsg('');
  }, [username, password])

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {

          const response = await authService.login(username, password);
          
          let roles = response?.perfil ? JSON.parse(response?.perfil?.roles) : [];

          let autorizado = response?.token ? (roles?.find(role => allowedRoles.includes(role)) ? true : false) : (false)
          
          if(autorizado){
            setAuth(response);
            history.push("/");
          }else{
            setErrMsg('Usuario no autorizado');
          }
          
      } catch (err) {
          //console.log(err)
          if (!err?.response) {
              setErrMsg('No Server Response');
          } else if (err.response?.status === 400) {
              setErrMsg('Usuario y contraseña invalidos');
          } else if (err.response?.status === 401) {
              setErrMsg('Unauthorized');
          } else {
              setErrMsg('Login Failed');
          }
          
      }
  }



  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Bienvenido
            </Heading>
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
            <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Username
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Username'
                size='lg'
                onChange={(e) => setUser(e.target.value)}
                value={username}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Contraseña
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='36px'
                fontSize='sm'
                type='password'
                placeholder='Contraseña'
                size='lg'
                onChange={(e) => setPwd(e.target.value)}
                value={password}
              />
              
              <Button
                fontSize='10px'
                type='submit'
                bg='teal.300'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
                >
                INICIAR SESION
              </Button>
            </FormControl>
            </form>
            
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            style={{alignSelf:"center"}}
            bgImage={signInImage}
            w='50%'
            h='50%'
            top='25%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
